body {
  margin: 0;
  font-family: 'Trebuchet MS',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Trebuchet MS',sans-serif;
}
.tos{
  width: 80%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.678);
}
.chat {
  position: fixed;
  width: 350px;
  right: 0;
  top: 80px;
  background: #151a24;
  border-left: 1px solid rgb(50, 50, 50);
  display: flex;
  flex-direction: column;
  z-index: 9999999;
  transition: transform 0.5s;
}
.show-chat {
  position: fixed;
  bottom: 0;
  right: 0px;
  transform: translateY(-50%);
  background: #151a24;
  padding: 13px;
  border: 1px solid #323232;
  cursor: pointer;
  transition-delay: 1s;
  transition: background 0.5s, transform 0.5s;
  font-size: 18px;
  z-index: 999999;
}
.show-chat-vis {
  transform: translateX(0);
}
.show-chat-invis{
  transform: translateX(-400%);
}
.chat.collapsed {
  transform: translateX(100%);
}

.me-bar {
  display: flex;
  height: 75px;
  border-bottom: 1px solid rgb(50, 50, 50);
  color: white;
  line-height: 75px;
  width: 100%;
  /* flex-shrink: 0;*/
  align-items: center;
  justify-content: center;
}

.chat-trivia {
  position: absolute;
  width: 320px;
  min-height: 120px;
  top: 133px; /* 73 */
  left: 50%;
  background: linear-gradient(270deg, #2991CC 0%, #1963A7 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  animation: triviaappear 0.5s linear forwards;
  z-index: 500;
  overflow: hidden;
  display: flex;
}

@keyframes triviaappear {
  from { opacity: 0; transform: translate(-50%, -50%) scale(0); }
  to { opacity: 1; transform: translate(-50%, -50%) scale(1);}
}

.chat-trivia-left {
  width: 110px;
  position: relative;
  text-align: center;
  line-height: 0px;
}

.chat-trivia-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.nobr {
  border-right: 0;
}

.chat-trivia-left img {
  display: inline-block;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}

.chat-trivia-right-bot, .chat-trivia-right-top {

  padding: 5px 2px;
  width: 210px;
  font-size: 15px;
  text-align:center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}
.chat-trivia-right-top {
  

}

.trivia-congratz {
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
}

.chat-trivia-right-bot {
 
}


.wtit {
  color: #fff;
}

.helppop {
  max-width: 375px !important;
  animation: popanim 0.35s linear forwards;
}

@keyframes popanim {
  0% { opacity: 0; transform: scale(0.75); }
  50% { opacity: 0.5; transform: scale(1.1); }
  100% { opacity: 1; transform: scale(1);  }
}

.helppop p {
  font-size: 13px !important;
}

.footer {
  width: calc(100% + 30px);
  height: 100px;
  background: #131921;
  margin: -15px;
  padding: 15px;
  padding-bottom: 105px !important;
  margin-top: 25px;
}

.footer-content {
  width: 500px;

  display: flex;
  justify-content: space-between;

}

.footer-el {
width: 33.3%;
}
.footer-el h5 {
  margin-bottom: 0 !important;
}

.footer-el * {
  line-height: 23px;
}

.footer-el a {
  color: #fff !important;
  opacity: 0.5;
  font-size: 12px;
  transition: opacity 0.5s;
  display: block;
}

.footer-el a:hover {
  opacity: 0.75;
}

.mt-0 {
  margin-top: 0 !important;
}

.ctflexie {
  display: flex;
  justify-content: space-between;
}

.ctprize {
  width: 100%;
  text-align: center;
}

.trivia-time {
  width: 100%;
font-weight: bold;
line-height: 18px;
/* identical to box height */
color: #FFFFFF;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.chat__three-dots {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  cursor: pointer;
}
.chat__input-container {
  position: relative;
}
.input .chat__input{
  background-color: #1963A7;
}

.chat__settings {
  position: absolute;
  bottom: 60px;
  left: 10px;
  background: red;
  width: calc(100% - 20px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background: #13161e;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.chat__setting {
  display: flex;
  padding: 8px 15px;
  align-items: center;
  color: rgb(150, 150, 150);
  cursor: pointer;
}
.chat__setting:hover {
  background: #191d27;
}
.chat__setting img {
  width: 30px;
  margin-right: 10px;
}

.chat__settings .img--rules {
  width: 22px;
}

.chat__settings .img--chat {
  margin-left: -3px;
}
.chat__settings .img--sound {
  margin-left: -3px;
}

.chat__settings .img-container {
  width: 40px;
  font-size: 25px !important;
}

.modal--rules {
  left: auto !important;
  transform: none !important;
  top: 65px !important;
  bottom: 0 !important;
  right: 0px;
  width: 350px !important;
  
}

.blurredchat {
  filter: blur(5px);
}

.levelBox {
    box-sizing: border-box;
    border-radius: 5px;
    margin-right: 10px;
    font-size: 9pt;
}

.levelBox.rank1 {
  color:#90ee90;
}

.levelBox.rank2 {
  background-color:#cd412a;
  background-color:var(--red)
}

.levelBox.rank9, l {
  color:#f08080
}
.levelBox.rankServer {
  color: #add8e6;
}
.chat__message .mowner {
  background: linear-gradient(
90deg
,#c50,#8b0000);
}

.chat__message .mServer {
  background: linear-gradient(
    90deg
    ,#5b75e9,#25008b);
}

.chat__message .mmod {
    background: linear-gradient(
90deg
,#04b93b,#008b45);
}

.me-bar a {
  color: white;
}

.me-bar .info .profile-pic {
  width: 50px;
  height: 50px;
  margin: 0 10px;
}

.me-bar .info {
  margin-left: auto;
  cursor: pointer;
}

.logout {
margin-left: 10px;
cursor:pointer;
}

.me-bar .login {
  cursor: pointer;
}

.me-bar .info {
  background: linear-gradient(180deg,#181d28,#16191c);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.chat__messages {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}

.chat__input {
  width: calc(100% - 100px);
  margin: 10px;
  padding-right: 40px;
}

.chat__message {
  display: flex;
  color: white;
  padding: 5px 15px;
}

.message__name {
  color: rgb(255, 255, 255);
  font-size: 12px;
  opacity: 0.5;
  transition: 150ms;
  cursor: pointer;
}

.message__name:hover {
  opacity: 0.9;
}

.message__pic {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  align-self: flex-end;
}

.message__text {
  background: rgb(40, 50, 60);
  border-radius: 5px;
  border-bottom-left-radius: 0;
  padding: 7px;
  font-size: 14px;
  max-width: 264.01px;
  word-wrap: break-word;
}

.header--mobile,
.header--desktop {
  visibility: hidden;
}

.chat-bar {
  background: #151a24;
  border-bottom: 1px solid rgb(50, 50, 50);
  display: flex;
  padding: 10px;
  align-items: center;
  height: 65px;
}

.chat-bar img {
  margin-right: 5px;
}

.chat-bar-social-icon {
  color: #8B8E93;
  cursor: pointer;
  font-size: 25px;
  transition: color 0.25s;
}

.chat-bar-social-icon:not(:last-child) {
margin-right: 5px !important;
}

.icon-twitter:hover {
  color: #1DA1F2;
}

.icon-discord:hover {
  color: #5865F2;
}

.icon-steam:hover {
  color: #fff;
}

.chat-bar-social {
  width: 25px;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}

.chat-langs__active-lang {
  border: 1px solid rgb(50, 50, 50);
  background: #12161e;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  border-radius: 5px;
  color: rgb(150, 150, 150);
  padding: 5px 10px;
  position: relative;
  align-items: center;
  cursor: default;
}
.message__body{
  max-width: 80%;
}

.chat-langs__active-lang .right {
  margin-left: auto;
  display: flex;
}

.right {
  margin-left: auto;
  display: flex;
}

.chat-red-dot {
  width: 5px;
  height: 5px;
  background: radial-gradient(50% 50% at 50% 50%, #CD412A 39.06%, #F96952 100%);
  box-shadow: 0px 0px 5px 1px #D0442D;
  border-radius: 50%;
  align-self: center;
  margin-right: 5px;
  animation: blinkanim 2s linear infinite;
}

@keyframes blinkanim {
  0% { box-shadow: 0px 0px 5px 1px #D0442D; }
  50% { box-shadow: 0px 0px 10px 2px #ec2000; }
  100% { box-shadow: 0px 0px 5px 1px #D0442D; }
}

.steam-login {
  cursor: pointer;
  margin-right: 7.5px;
}

.chat-langs__other-langs {
  position: absolute;
  background: red;
  width: 100%;
  right: 0;
  top: 33px;
  background: linear-gradient(180deg, #191e29, #16191c);
  border-radius: 5px;
  border: 1px solid rgb(50, 50, 50);
  padding: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 501;
}
  
.chat-langs__other-lang {
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-content: flex-start;
}

.chat-langs__other-lang div:last-child {
  margin-left: auto;
}

.chat {
  height: calc(100vh - 80px);
}

/* Disable chat from collapsing on desktop */
.chat.hidden {
  display: flex !important;
}
.hidden{
  display: none
}
.img{
  height: 64px;
  border: 0;
  vertical-align: middle;
}