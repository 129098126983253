.popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.popup-inner{
    position: relative;
    padding: 32px;
    width:50%;
    background: url(../images/black.png);
    
    max-height: 650px;
    border-radius: 10px;
    outline: 5px groove rgb(187, 192, 194);
    border:5px solid black;
    font-family: 'arial', sans-serif;
}

.popup-inner .close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    color:gray;
    font-weight: bold;
    font-family: Verdana, sans-serif;
    border: none;

}
.close-btn:hover{
    color: white;
    cursor: pointer;
}