.siteInfo{
  position: absolute;
  left: 10px;
  top: 120px;
  width: 350px;
  background-color: rgba(0, 0, 0, 0.705);
  border: 2px solid red;
  border-radius: 2px;
  padding: 5px;
}
.timer{
    display: grid;
    top: 20px;
    bottom: 20px;
    place-items: start center;
}

.row1{
  height: 100%;
  float: left;
  width: 20%;

}
.row2{
  height: 100%;
  float: left;
  width: 60%;

}
.row3{
  height: 100%;
  float: left;
  width: 20%;

}

.jackpotDeposit{
  width: 666px;
  border: 1px solid rgb(97, 97, 97);
  height: 69px;
  line-height: 69px;
  text-align: center;
  font-size: 39px;
  background-color:rgb(0, 0, 0);
  cursor: pointer;
  border-radius: 10px;
}

#jackpotRoll{
  width: 666px;
  max-width: 666px;
  border: 2px solid red;
  overflow: hidden;
}

#roll-Imgs {
    overflow: visible;
    width: 16000px!important;
    position: relative;
    float: left;
    right: 15px;
    /*-webkit-transition: right 6s cubic-bezier(.44,0,0,1);*/
    transition: right 6s cubic-bezier(.44,0,0,1);
    /*transition: right 6s cubic-bezier(.8,-0.23,.39,1.11);*/
    height: 64px;
    white-space: nowrap;
    font-size: 0;

}
.line{
  vertical-align: middle;
  text-align: center;
  position:absolute;
  left: calc(50% - 3px);
  z-index: 1;
  width: 3px
}
.jackpotItem {
  color: #fff;
  border: 2px solid #333;
  background-color: #404040;
  font-weight: 700;
  width: 65px;
  height: 84px;
  border-radius: 6px;
  display: block;
  margin: 2px auto;
  box-sizing: border-box;
}
.jackpotItem img{
  width: 62px;
  height: 62px;
  border: 0;
}
.jackpotItem p{
margin-top: 0px;
text-align: center;
}

.jackpotUser{
  float: left;
  margin: 0 auto;
  margin-right: 10px;
}
.center{
  width: 50%;
  margin: 0 auto;
}
.jackpotEntryHeader p{
  line-height: 70px;
}
.jackpotEntries{
  min-height: 500px;
  width: 666px;
  border: 1px solid rgb(97, 97, 97);
  position: relative;
  margin-top: 5px;
  border-radius: 5px;
  background-color:rgba(0, 0, 0, 0.808)
}
.jackpotEntryHeader{
  height: 70px;
  
}
.jackpotEntriesText{
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 30px;
  background-color: rgb(141, 140, 143);
  margin-left: 0 !important;
  margin-right:0 !important;
}
.jackpotEntriesText p{
  margin: auto;
  
}

.base-timer {
    position: relative;
    width: 300px;
    height: 300px;
  }
  
  .base-timer__svg {
    transform: scaleX(-1);
  }
  
  .base-timer__circle {
    fill: none;
    stroke: none;
  }
  
  .base-timer__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }
  
  .base-timer__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }
  
  .base-timer__path-remaining.green {
    color: rgb(65, 184, 131);
  }
  
  .base-timer__path-remaining.orange {
    color: orange;
  }
  
  .base-timer__path-remaining.red {
    color: red;
  }
  
  .base-timer__label {
    position: absolute;
    width: 300px;
    height: 300px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
  }

.img{
  height: 64px;
  border: 0;
  vertical-align: middle;
}