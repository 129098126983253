
.itemContainer{
    height: 160px;
    width: 160px;
    background-color: #404040;
    text-align: center;
    display: inline-block;
    font-weight: 700;
    border-radius: 5px;
    border: 2px solid #333;
    margin: 2px;
    vertical-align: middle;
}
.itemContainer.active{
    background-color: yellow
}
.itemContainer img{
    width: 100px;
    height: 100px;
    border: 0;
}
.itemContainer p{
    margin: 0;
    border: 0;
    
}
.pBtn{
    background-color: rgb(66, 66, 66); 
     border: none;
     margin-right: 5px;
     position:relative;
     display: block;
     float: left;
     color: white;
     width: 175px;
     height: 30px;
     text-align: center;
     font-size: 18px;
     margin-top:5px;
}
.pBtn:disabled  {
    opacity: 0.2;
    cursor: not-allowed;
    border: 1px solid rgb(255, 255, 255);
}
.pBtn:hover  {
    background-color: rgb(37, 37, 37); 
    border: 1px solid rgb(255, 255, 255);
}
.inventory{
    min-height: 100px;
    max-height: 400px;
    overflow-y: auto;
    vertical-align: middle;
}
.inventory button{
    display: block
}

#roll-Imgs {
    overflow: visible;
    width: 16000px!important;
    position: relative;
    float: left;
    right: 15px;
    -webkit-transition: right 6s cubic-bezier(.075,.82,.165,1);
    transition: right 6s cubic-bezier(.075,.82,.165,1);
    height: 64px;
    white-space: nowrap;
    font-size: 0;
}
.picked{
    border: 5px solid green;
}
img {
    vertical-align: middle;
}
body{
    box-sizing: border-box;
}