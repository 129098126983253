body {
    background: url(../images/treebackground.png);
    background-attachment: fixed;
    background-size:cover;
    color: white !important;
  }

.info{
    border: 2px solid #616161!important;
    text-align: center;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 5px;
    width: calc(100% - 370px);
    height: 100px;
    line-height: 25px;
    background-color: rgba(0, 0, 0, 0.842);
    font-family: 'Trebuchet MS',sans-serif;
    font-size: 20px;
}
.row3 p{
    color: white;
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 20px;
    margin-right: 10px;
}
.row1 div{
    color: rgb(206, 206, 206);
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 20px;
    margin-left: 85px;
}
.row1{
    height: 100%;
    float: left;
    width: 20%;
  
}
.row2{
height: 100%;
float: left;
width: 60%;

}
.row3{
height: 100%;
float: left;
width: 20%;
}
.cfBtn{
 background-color: rgb(66, 66, 66); 
  border: none;
  margin-right: 5px;
  position:relative;
  display: block;
  float: left;
  color: white;
  width: 75px;
  height: 30px;
  text-align: center;
  font-size: 18px;
}
.cfBtn:disabled  {
    opacity: 0.2;
    cursor: not-allowed;
    border: 1px solid rgb(255, 255, 255);
  }
.coinflipItem{
    color: #fff;
    border: 2px solid #333;
    background-color: #404040;
    font-weight: 700;
    width: 65px;
    height: 65px;
    border-radius: 6px;
    display: block;
    margin: 2px auto;
    box-sizing: border-box;
    float: left;
}
.coinflipItem img{
    width: 62px;
    height: 62px;
    border: 0;
}
.coinflipItem p{
    margin-top: 0px;
    text-align: center;
}
.info p{
    margin: 0
}
.coinflipText{
margin: auto;
height: 30px;
line-height: 30px;
text-align: center;
font-size: 15px;
background-color: rgba(0, 0, 0, 0.842);
;

}
.coinflipCreate{
    margin-top: 10px;
    background-color: rgb(66, 66, 66);
    border-radius: 8px;
    cursor: pointer;
    margin-right: 5%;
}
.flipz{
    width: calc(100% - 370px) !important;
}
.flipEntry{
    border: 2px solid rgb(122, 127, 129);
    background-color: rgba(0, 0, 0, 0.623);
}
.money{
    color:rgb(17, 140, 79) !important;
}
.flipEntry p{
    float: left;
}
.profileImage{
    float: left;
    border-radius: 50%;
    position: relative;
    margin-left: 8px;
}
.pickImage0{
    position: relative;
    height: 20px;
    width: 20px;
    top: 5px;
    left: -15px;
}
.pickImage1{
    height: 10px;
    width: 10px;
    top: 5px;
    left: 60px;
}

/* coinflip animation */
.pick img{
    width: 100px;
    height: 100px;
}


@-webkit-keyframes rotate900 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(900deg); -moz-transform: rotateY(900deg); transform: rotateY(900deg); }
}

@keyframes rotate900 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(900deg); -moz-transform: rotateY(900deg); transform: rotateY(900deg); }
}

@-webkit-keyframes rotate1080 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1080deg); -moz-transform: rotateY(1080deg); transform: rotateY(1080deg); }
}

@keyframes rotate1080 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1080deg); -moz-transform: rotateY(1080deg); transform: rotateY(1080deg); }
}

@-webkit-keyframes rotate1260 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1260deg); -moz-transform: rotateY(1260deg); transform: rotateY(1260deg); }
}

@keyframes rotate1260 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1260deg); -moz-transform: rotateY(1260deg); transform: rotateY(1260deg); }
}

@-webkit-keyframes rotate1440 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1440deg); -moz-transform: rotateY(1440deg); transform: rotateY(1440deg); }
}

@keyframes rotate1440 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1440deg); -moz-transform: rotateY(1440deg); transform: rotateY(1440deg); }
}

@-webkit-keyframes rotate1620 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1620deg); -moz-transform: rotateY(1620deg); transform: rotateY(1620deg); }
}

@keyframes rotate1620 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1620deg); -moz-transform: rotateY(1620deg); transform: rotateY(1620deg); }
}

@-webkit-keyframes rotate1800 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
}

@keyframes rotate1800 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
}

@-webkit-keyframes rotate1980 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
}

@keyframes rotate1980 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
}

@-webkit-keyframes rotate2160 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(2160deg); -moz-transform: rotateY(2160deg); transform: rotateY(2160deg); }
}

@keyframes rotate2160 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(2160deg); -moz-transform: rotateY(2160deg); transform: rotateY(2160deg); }
}