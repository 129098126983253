:root{
    --primary: #3acbf7;

}
.btnn{
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btnn:hover{
    padding: 8px 20px;
    transition: all 0.3s ease-out;
    background: white;
    color: #6568F4;
    transition:  250ms;
}

.btnn--primary{
    background-color: transparent;
}
.btnn--outline{
    background-color: transparent;
    color: white;
    padding: 8px 20px;
    border-radius: 20px;
    border: 1px solid transparent;
    transition: all 0.3s ease-out;

}

.btnn--medium{
    padding: 8px 20px;
    border-radius: 4px;
    font-size: 1.5rem;
    color: white;
    text-align: -webkit-match-parent;
}
.btnn--large{
    padding: 12px 20px;
    border-radius: 4px;
    font-size: 20px;
    color: white; 
}