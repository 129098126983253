.Creator img p{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.Creator {
    width: 50%;
    height: 20%;
    float: left;
    align-content: center;
    font-size: 100% !important;
}
.Opponent{
    width: 45%;
    height: 20%;
    float: left;
    text-align: right;
    align-items: right;
    font-size: 100% !important;
}
.Opponent img p{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}
.creatorItems{
    float:left;
    width:40%;
    top: 256px;
    position: absolute;
    height: 55%;
    border-radius: 10px;
    left: 5%;
    
}
.opponentItems{
    float:left;
    width:40%;
    top: 256px;
    position: absolute;
    height: 55%;
    border-radius: 10px;
    left: 55%;
    
    
}
.header{
    height: 10%;
    text-align: center;
    border: 8px solid rgb(136, 136, 136);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom:5px solid black;
    background-color:rgb(136, 136, 136);
    color:rgb(0, 0, 0);
    font-size: 100%;
    font-weight: bolder;
    font-family: Arial, sans-serif;
}



.items{
    height: 85%;
    border: 8px solid rgb(136, 136, 136);
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    background-color: rgb(136, 136, 136);
    overflow-y: auto;
}

.item {
    color: rgb(0, 0, 0);
    background-color: rgb(136, 136, 136);
    font-weight: 666;
    width: 22%;
    height: 30%;
    border-radius: 6px;
    display: inline-block;
    margin: 1%;
  }
  .item img{
    width: 66px;
    height: 66px;
    border: 0;
  }

  .item p{
    margin: 0;
    text-align: center;
  }

[data-tooltip]:before {            
    position : absolute;
    content : attr(data-tooltip);
    opacity : 0;
    top: -1px;
    background-color:#333
}
[data-tooltip]:hover:before {        
    opacity : 1;
}
.CF-popup{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.CF-inner{
    position: absolute;
    padding: 3%;
    width:50%;
    background: url(../images/black.png);

    height: 666px;
    border-radius: 10px;
    outline: 5px groove rgb(187, 192, 194);
    border:5px solid black;
    font-family: 'arial', sans-serif;
    font-size: 100%;
    font-weight: bold;
    color:rgb(136, 136, 136)
}

.CF-inner .close-btn{
    position: absolute;
    top: 16px;
    right: 16px;
    background: none;
    color:gray;
    font-weight: bold;
    font-family: Verdana, sans-serif;
    border: none;
}
.close-btn:hover{
    color: white;
    cursor: pointer;
    }
#coin-flip-cont {
width: 256px;
height: 256px;
position: absolute;
top: 0%;
left: calc(50% - 128px);
text-align: center;
}

#coin {
position: relative;
width: 256px;
transform-style: preserve-3d;
}

#coin .front, #coin .back {
position: absolute;
width: 256px;
height: 256px;
z-index: 99;
background-size: 256px !important;
}

#coin .front {
border-radius: 50%;
background: url(../images/rust.png);
}

#coin .back {

border-radius: 50%;
background: url(../images/rust_2.png);
}

#coin.animation900 {
-webkit-animation: rotate900 3s linear forwards; 
animation: rotate900 3s linear forwards;
}

#coin.animation1080 {
-webkit-animation: rotate1080 3s linear forwards; 
animation: rotate1080 3s linear forwards;
}

#coin.animation1260 {
-webkit-animation: rotate1260 3s linear forwards; 
animation: rotate1260 3s linear forwards;
}

#coin.animation1440 {
-webkit-animation: rotate1440 3s linear forwards; 
animation: rotate1440 3s linear forwards;
}

#coin.animation1620 {
-webkit-animation: rotate1620 3s linear forwards; 
animation: rotate1620 3s linear forwards;
}

#coin.animation1800 {
-webkit-animation: rotate1800 3s linear forwards; 
animation: rotate1800 3s linear forwards;
}

#coin.animation1980 {
-webkit-animation: rotate1980 3s linear forwards; 
animation: rotate1980 3s linear forwards;
}

#coin.animation2160 {
-webkit-animation: rotate2160 3s linear forwards; 
animation: rotate2160 3s linear forwards;
}

@-webkit-keyframes rotate900 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(900deg); -moz-transform: rotateY(900deg); transform: rotateY(900deg); }
}

@keyframes rotate900 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(900deg); -moz-transform: rotateY(900deg); transform: rotateY(900deg); }
}

@-webkit-keyframes rotate1080 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1080deg); -moz-transform: rotateY(1080deg); transform: rotateY(1080deg); }
}

@keyframes rotate1080 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1080deg); -moz-transform: rotateY(1080deg); transform: rotateY(1080deg); }
}

@-webkit-keyframes rotate1260 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1260deg); -moz-transform: rotateY(1260deg); transform: rotateY(1260deg); }
}

@keyframes rotate1260 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1260deg); -moz-transform: rotateY(1260deg); transform: rotateY(1260deg); }
}

@-webkit-keyframes rotate1440 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1440deg); -moz-transform: rotateY(1440deg); transform: rotateY(1440deg); }
}

@keyframes rotate1440 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1440deg); -moz-transform: rotateY(1440deg); transform: rotateY(1440deg); }
}

@-webkit-keyframes rotate1620 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1620deg); -moz-transform: rotateY(1620deg); transform: rotateY(1620deg); }
}

@keyframes rotate1620 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(2160deg); -moz-transform: rotateY(2160deg); transform: rotateY(2160deg); }
}

@-webkit-keyframes rotate1800 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
}

@keyframes rotate1800 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1800deg); -moz-transform: rotateY(1800deg); transform: rotateY(1800deg); }
}

@-webkit-keyframes rotate1980 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
}

@keyframes rotate1980 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(1980deg); -moz-transform: rotateY(1980deg); transform: rotateY(1980deg); }
}

@keyframes rotate2160 {
from { -webkit-transform: rotateY(0); -moz-transform: rotateY(0); transform: rotateY(0); }
to { -webkit-transform: rotateY(2160deg); -moz-transform: rotateY(2160deg); transform: rotateY(2160deg); }
} 